import React, { Fragment } from 'react'
import { Periods, TagItemType } from '@commonstock/common/src/types'
import {
  useGetTopMemos,
  MemoFeedItem,
  PostFeedItem,
  FeedItem,
  PostFeedOutput,
  FeedOutput,
  isMemoFeedItem
} from '@commonstock/common/src/api/feed'
import { SpacerV } from '../../composing/Spacing'
import { DiscoverTopMemosSkeleton } from './Discover.skeleton'
import MemoCardSmall from '../memo/MemoCardSmall'
import { SkeletonWrapper } from '../../components/SuspenseSkeleton'
import { useGetUsersPosts } from '@commonstock/common/src/api/post'
import PostCardSmall from 'src/scopes/posts/PostCardSmall'
import { useGetUsersMemos } from '@commonstock/common/src/api/memo'
import { useGetTaggedContent } from '@commonstock/common/src/api/tag'
import PreviewCard from 'src/components/PreviewCard'
import { Routes } from '../nav/constants'
import { destructContentFeedItem } from '../content/utils'
import { UrlObject } from 'url'
import { useGoToHome } from '../nav/navHooks'

export enum RelatedContentTypes {
  ByAuthor = 'ByAuthor',
  ByAsset = 'ByAsset',
  ByTrending = 'ByTrending'
}

type GetTitleAndLinkProps = {
  relatedBy: RelatedContentTypes
  userUuid?: string
  symbol?: string
  type?: string
  homeLink: UrlObject
}
function getTitleAndLink({
  relatedBy,
  userUuid,
  symbol,
  type,
  homeLink
}: GetTitleAndLinkProps): [string, string | UrlObject | undefined] {
  switch (relatedBy) {
    case RelatedContentTypes.ByAuthor:
      return ['By this author', userUuid ? Routes.userMemos(userUuid) : undefined]
    case RelatedContentTypes.ByAsset:
      return ['More on this asset', symbol && type ? Routes.assetMemos(symbol, type) : undefined]
    case RelatedContentTypes.ByTrending:
      return ['Trending this week', homeLink]
    default:
      return ['', undefined]
  }
}

function useRelatedBy(
  relatedBy: RelatedContentTypes,
  { user_uuid, resources }: PostFeedItem | MemoFeedItem | FeedItem
): [FeedOutput[] | FeedOutput | undefined, PostFeedOutput[] | undefined, boolean, boolean] {
  // ByAuthor
  const [relatedByUserMemos, relatedByUserMemosFail, , , , firstPageLoadedAuthorMemo] = useGetUsersMemos(
    { query: { limit: 2, target_uuid: user_uuid || '' } },
    { paused: !user_uuid || relatedBy !== RelatedContentTypes.ByAuthor }
  )

  const [relatedByUserPosts, relatedByUserPostsFail, , , , firstPageLoadedAuthorPost] = useGetUsersPosts(
    { query: { limit: 1, target_uuid: user_uuid || '' } },
    { paused: !user_uuid || relatedBy !== RelatedContentTypes.ByAuthor }
  )

  // ByAsset
  const { symbol, type } = Object.values(resources.assets)[0] || { symbol: '', type: '' }
  const [relatedByAssetMemos, relatedByAssetMemosFail, relatedByAssetMemosPending] = useGetTaggedContent({
    query: { tag: `ASSET|${symbol}:${type}`, content_types: TagItemType.MEMO_PLUS, limit: 3 }
  })
  // @TODO: We don't have an endpoint for tagged posts

  // ByTrending
  const [relatedByTrendingMemos, relatedByTrendingMemosFail, , , , firstPageLoadedTrendingMemo] = useGetTopMemos({
    query: { period: Periods['1W'], limit: 3 }
  })

  if (relatedBy === RelatedContentTypes.ByAuthor) {
    return [
      relatedByUserMemos,
      relatedByUserPosts,
      firstPageLoadedAuthorPost && firstPageLoadedAuthorMemo,
      !!relatedByUserMemosFail && !!relatedByUserPostsFail
    ]
  }
  if (relatedBy === RelatedContentTypes.ByAsset) {
    return [
      !symbol || !type ? [] : relatedByAssetMemos,
      undefined,
      !relatedByAssetMemosPending,
      !!relatedByAssetMemosFail
    ]
  }
  if (relatedBy === RelatedContentTypes.ByTrending) {
    return [relatedByTrendingMemos, undefined, !!firstPageLoadedTrendingMemo, !!relatedByTrendingMemosFail]
  }
  return [undefined, undefined, false, false]
}

export default function RelatedBy({
  relatedBy,
  feedItem
}: {
  relatedBy: RelatedContentTypes
  feedItem: PostFeedItem | MemoFeedItem | FeedItem
}) {
  let relatedMemos: FeedItem[] | undefined = undefined
  let relatedPosts: PostFeedItem[] | undefined = undefined

  const [relatedByMemos, relatedByPosts, firstPageLoaded, failed] = useRelatedBy(relatedBy, feedItem)

  relatedMemos = Array.isArray(relatedByMemos) ? relatedByMemos?.flatMap(item => item.items) : relatedByMemos?.items
  relatedPosts = relatedByPosts?.flatMap(item => item.items)

  const { homeLink } = useGoToHome()
  const { user } = destructContentFeedItem(feedItem)
  const { symbol, type } = Object.values(feedItem.resources.assets)[0] || { symbol: '', type: '' }
  const [title, link] = getTitleAndLink({ relatedBy, userUuid: user?.username, symbol, type, homeLink })

  return (
    <SkeletonWrapper
      pending={!firstPageLoaded}
      skeleton={
        <>
          <DiscoverTopMemosSkeleton />
          <SpacerV />
        </>
      }
      failed={failed || (relatedMemos && !relatedMemos.length) || (relatedPosts && !relatedPosts.length)}
    >
      {!!relatedMemos?.length && (
        <PreviewCard title={title || ''} link={link}>
          {relatedMemos.filter(isMemoFeedItem).map(memo => (
            <Fragment key={memo.uuid}>
              <SpacerV />
              <MemoCardSmall memoFeedItem={memo} />
            </Fragment>
          ))}
          {
            !!relatedPosts?.map(post => (
              <Fragment key={post.uuid}>
                <SpacerV />
                <PostCardSmall postFeedItem={post} />
              </Fragment>
            ))
          }
        </PreviewCard>
      )}
      <SpacerV />
    </SkeletonWrapper>
  )
}
